<template>
<div>
  <b-card>
    <b-tabs card>
      <b-tab title="Tab 1" active>
        <b-card-text>Tab contents 1</b-card-text>
      </b-tab>
      <b-tab title="Tab 2">
        <b-card-text>Tab contents 2</b-card-text>
      </b-tab>
    </b-tabs>
  </b-card>
</div>
</template>

<script>
import {
  BButton,
  BCol,
  BRow,
  BCard,
  BCardText,
  BFormDatepicker,
  BTab,
  BTabs,
} from "bootstrap-vue"
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCard,
    BCardText,
    BFormDatepicker,
    BTab,
    BTabs,
  },
  directives: {
    Ripple,
  },
}
</script>
